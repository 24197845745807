<template>
  <div class="login-page">
    <div class="tabs-nav">
      <div :class="['item-box', { check: active === 0 }]" @click="active = 0">
        Log in
      </div>
      <div :class="['item-box', { check: active === 1 }]" @click="active = 1">
        Sign up
      </div>
    </div>
    <div class="tabs-content">
      <p class="hint">{{ hint }}</p>
      <div v-show="active === 0" class="login-box">
        <el-form
          :model="loginForm"
          ref="loginForm"
          :rules="rules"
          label-position="top"
          size="medium"
          @keyup.enter.native="login()"
          @submit.native.prevent
        >
          <el-form-item label="Email" prop="username">
            <el-input v-model="loginForm.username"></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input show-password v-model="loginForm.password"></el-input>
          </el-form-item>
          <div class="forgot-pass">
            <span>
              Forgot your password?
              <router-link class="link g-ff-6" to="/Profile/forget"
                >Click here</router-link
              >
            </span>
          </div>
          <el-form-item size="large">
            <el-button type="primary" @click="() => login()">Log in</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="active === 1" class="signup-box">
        <p class="hint add_hint">*indicates required field.</p>
        <el-form
          :model="signupForm"
          ref="signupForm"
          :rules="rules"
          label-position="top"
          size="medium"
          @keyup.enter.native="signup"
          @submit.native.prevent
        >
          <el-form-item label="Status" prop="memberType">
            <select-status
              v-model="signupForm.memberType"
              style="width: 100%"
            />
          </el-form-item>
          <div class="line-box">
            <el-form-item label="First Name" prop="fullName">
              <el-input v-model="signupForm.fullName" />
            </el-form-item>
            <el-form-item label="Last Name" prop="lastName" class="lastName">
              <el-input v-model="signupForm.lastName" />
            </el-form-item>
          </div>

          <el-form-item label="Phone" prop="phone">
            <el-input
              v-model="signupForm.phone"
              @input="inputPhone"
              maxlength="10"
            />
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <div class="email">
              <el-input v-model="signupForm.email" />
              <el-button
                type="primary"
                class="verify-email-btn width_small"
                :loading="verifyEmailBtn.loading"
                :disabled="Boolean(verifyEmailBtn.status)"
                @click="verifyEmail"
              >
                {{ verifyEmailBtn.label }}
              </el-button>
            </div>
          </el-form-item>
          <el-form-item label="Code" prop="code">
            <el-input v-model="signupForm.code" />
          </el-form-item>
          <el-form-item label="Password" prop="password1">
            <el-input show-password v-model="signupForm.password1" />
          </el-form-item>
          <el-form-item label="Confirm Password" prop="password2">
            <el-input show-password v-model="signupForm.password2" />
          </el-form-item>
          <el-form-item prop="agreementConfirm" class="agreement-check">
            <el-checkbox
              class="agreement-confirm"
              v-model="signupForm.agreementConfirm"
            >
              <span class="agreement-confirm-txt">
                I agree to the LPMAC Resident
                <span class="link" @click="goTc('termsAndConditions')">
                  Terms and Conditions
                </span>
                &
                <span class="link" @click="goTc('privacyPolicy')">
                  Privacy Policy.
                </span>
              </span>
            </el-checkbox>
          </el-form-item>
          <el-form-item size="large">
            <el-button type="primary" @click="signup">Sign up</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import SelectStatus from "@/components/SelectStatus";
import validate from "@/utils/validateForm";
import user from "@/utils/user";

export default {
  name: "login",
  components: { SelectStatus },
  data() {
    return {
      active: 0, // 用于功能 0-登录 1-注册
      rules: {
        username: [
          {
            required: true,
            message: "Please enter a valid email address",
            trigger: "blur",
          },
          { validator: validate.username, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "Password cannot be empty",
            trigger: "blur",
          },
        ],
        memberType: [
          {
            required: true,
            message: "Status cannot be empty",
            trigger: "blur",
          },
        ],
        fullName: [
          {
            required: true,
            message: "First Name cannot be empty",
            trigger: "blur",
          },
          { validator: validate.name, trigger: "blur" },
        ],
        lastName: [
          {
            required: true,
            message: "Last name cannot be empty",
            trigger: "blur",
          },
          { validator: validate.name, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "Phone cannot be empty", trigger: "blur" },
          { validator: validate.phone, trigger: "blur" },
        ],
        email: [
          { required: true, message: "Email cannot be empty", trigger: "blur" },
          { validator: validate.email, trigger: "blur" },
        ],
        code: [
          {
            required: true,
            message: "Verification code cannot be empty",
            trigger: "blur",
          },
        ],
        password1: [
          {
            required: true,
            message: "Password cannot be empty",
            trigger: "blur",
          },
          {
            min: 8,
            message: "Please enter at least 8 digits of numbers or letters",
            trigger: "blur",
          },
        ],
        password2: [
          {
            required: true,
            message: "Please re-enter your password",
            trigger: "blur",
          },
          { validator: this.confirmPassword, trigger: "blur" },
        ],
        agreementConfirm: [
          { validator: validate.agreementConfirm, trigger: "change" },
        ],
      }, // 校验规则
      verifyEmailBtn: {
        loading: false,
        label: "Verify Email",
        status: 0, // 倒计时 为0是可操作
      },
      loginForm: {
        username: "",
        password: "",
      },
      signupForm: {
        memberType: "",
        fullName: "",
        lastName: "",
        phone: "",
        email: "",
        code: "",
        password1: "",
        password2: "",
        agreementConfirm: false,
      },
    };
  },
  computed: {
    hint() {
      const arr = ["Welcome Resident", "Step 1: Please fill  in your details"];
      return arr[this.active];
    },
  },
  watch: {
    active(e) {
      this.setSideBarStatus(e - 1);
    },
  },
  created() {
    //把键盘监听事件置空
    document.onkeydown = "";
  },
  mounted() {
    // bar
    this.navBarShow(false);
    this.sideBarShow();
    this.setSideBarStatus(-1);
  },
  destroyed() {
    this.navBarShow();
  },
  methods: {
    ...mapMutations("bar", ["navBarShow", "sideBarShow", "setSideBarStatus"]),
    ...mapActions("user", ["getUserInfo"]),
    async login(isSignup = false) {
      if (!(await this.$refs.loginForm.validate())) return false;
      const options = [
        this.$api.login,
        null,
        {
          headers: { Authorization: this.$api.authorization },
          noToken: true,
          params: {
            grant_type: this.$api.grantType,
            domain: this.$api.domain,
            ...this.loginForm,
          },
        },
      ];
      this.$axios.post(...options).then((res) => {
        if (res.resp_code === 0) {
          this.$notify.success("Successfully logged in");
          user.setToken(res.datas.access_token);
          this.getUserInfo();
          if (isSignup) {
            this.setSideBarStatus(0);
            this.$router.push("/contactDetails?type=signup");
          } else {
            this.$router.push("/");
          }
        }
      });
    },
    async signup() {
      /*this.$notify.success("Registered successfully");
      this.loginForm.username = this.signupForm.email;
      this.loginForm.password = this.signupForm.password2;
      this.login(true);*/
      if (!(await this.$refs.signupForm.validate())) return false;
      const options = [
        this.$api.signIn,
        {
          ...this.signupForm,
          pwd: this.signupForm.password2,
          name: this.signupForm.fullName + "," + this.signupForm.lastName,
        },
        { headers: { domainName: this.$api.domain }, noToken: true },
      ];
      this.$axios.post(...options).then((res) => {
        if (res.code === "1000") {
          this.$notify.success("Registered successfully");
          this.loginForm.username = this.signupForm.email;
          this.loginForm.password = this.signupForm.password2;
          this.login(true);
        }
      });
    },
    // 获取验证码
    verifyEmail() {
      let msg = "msg";
      this.$refs.signupForm.validateField("email", (err) => (msg = err));
      if (msg) return false;
      this.verifyEmailBtn.loading = true;
      const options = [
        this.$api.signInEmail,
        {
          email: this.signupForm.email,
          name: this.signupForm.fullName + "," + this.signupForm.lastName,
        },
        { headers: { domainName: this.$api.domain }, noToken: true },
      ];
      this.$axios.post(...options).then((res) => {
        this.verifyEmailBtn.loading = false;
        if (res.code === "1000") {
          this.verifyEmailBtnCount();
          this.$notify.success(
            "Verification code has been sent, please check your email."
          );
        }
        // else {
        //   this.$notify.warning(
        //     "Email is already registered. Use a email that is not registered yet and try again."
        //   );
        // }
      });
    },
    // 获取验证码按钮
    verifyEmailBtnCount(index = 60) {
      this.verifyEmailBtn.label = index ? index + "s" : "Verify Email";
      this.verifyEmailBtn.status = index;
      index--;
      index >= 0 && setTimeout(() => this.verifyEmailBtnCount(index), 1000);
    },
    // 手机号数字校验
    inputPhone(e) {
      const arr = e.match(/\d/g);
      if (!arr) this.signupForm.phone = "";
      else this.signupForm.phone = arr.join("");
    },
    // 密码校验
    confirmPassword(rule, value, callback) {
      if (value !== this.signupForm.password1) {
        callback(new Error("Password are not matching!"));
      } else {
        callback();
      }
    },
    //跳转
    goTc(url) {
      let routeUrl = this.$router.resolve({
        path: "/" + url,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
